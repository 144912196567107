<template>
  <v-card>
    <v-card-title>
      <base-badge-counter
        v-if="ppCount"
        :itemCount="ppCount"
        :useIcon="true"
        :leftMargin="5"
        viewIcon="mdi-clipboard-arrow-down"
        tooltipText="Total number of Pressure Pins"
      />
      <v-spacer />
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            fab
            dark
            top
            left
            color="warning"
            class="ma-2"
            v-on="on"
            v-show="isEditing"
            @click.stop="showSimpleDialog('discard')"
          >
            <v-icon>mdi-content-save-off</v-icon>
          </v-btn>
        </template>
        <span>Discard all changes</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            fab
            dark
            top
            left
            color="secondary"
            class="ma-2"
            v-on="on"
            v-show="isEditing"
            @click="saveAllInLine"
          >
            <v-icon>mdi-content-save-all</v-icon>
          </v-btn>
        </template>
        <span>Save all changes</span>
      </v-tooltip>
      <PressurePinForm
        :editMode="false"
        :override="override"
        :disabled="!isAdmin"
        :enabledViewMils="enabledViewMils"
        @saveData="saveForm"
      />
      <InputQtyTextField
        :disableStatus="!isAdmin"
        color="secondary"
        buttonText="Add Default Pressure Pin"
        actionText="Create Pressure Pin"
        toolbarText="Pressure Pins"
        @onAcceptInputQuantity="addDefaultHandler"
      />
    </v-card-title>
    <v-data-table
      v-model="selection"
      :headers="getHeaders(enabledViewMils)"
      :items="arrayList"
      :value="arrayList"
      :items-per-page="itemsPerPage"
      :show-select="bulkEditMode"
      disable-filtering
      item-key="pk"
      :item-class="itemRowBg"
      :footer-props="pageOptions"
      class="mb-5"
    >
      <template v-slot:[`item.data-table-select`]="props">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <span v-on="on">
              <v-checkbox
                dense
                hide-details
                :input-value="props.isSelected"
                @change="props.select($event)"
              ></v-checkbox>
            </span>
          </template>
          <span>Bulk edit for drop-down selections</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.name`]="props">
        <v-edit-dialog
          :return-value.sync="props.item.name"
          :large="isInitialState || isAdmin"
          @save="
            save('directSync', {
              pk: props.item.pk,
              data: {
                propsKey: 'name',
                name: props.item.name,
                edited: true,
              },
            })
          "
          @cancel="cancel"
          ><span
            :class="
              !props.item.name && showerror
                ? 'error--text'
                : bulkEditMode
                ? 'grey--text'
                : ''
            "
            >{{
              !props.item.name && showerror ? "Required" : props.item.name
            }}</span
          >
          <template v-slot:input>
            <v-text-field
              v-model="props.item.name"
              label="Name"
              type="text"
              :disabled="!isInitialState && !isAdmin"
            ></v-text-field>
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:[`item.x`]="props">
        <v-edit-dialog
          v-if="!enabledViewMils"
          :return-value="props.item.x"
          :large="isInitialState || isAdmin"
          @save="
            save('coordinate', {
              pk: props.item.pk,
              data: {
                propsKey: 'x',
                x: props.item.x,
                edited: true,
              },
            })
          "
          @cancel="cancel"
          ><span
            :class="
              (!props.item.x || +props.item.x == 0) && showerror
                ? 'error--text'
                : ''
            "
            >{{
              !props.item.x.toString().length > 0 && showerror
                ? "Required"
                : props.item.x
            }}</span
          >
          <template v-slot:input>
            <v-text-field
              v-model.number="props.item.x"
              label="X-Coordinate (mm)"
              type="number"
              step="0.0001"
              :disabled="!isInitialState && !isAdmin"
            ></v-text-field>
          </template>
        </v-edit-dialog>
        <v-edit-dialog
          v-else
          :return-value="props.item.x_mils"
          :large="isInitialState || isAdmin"
          @save="
            save('coordinate_mils', {
              pk: props.item.pk,
              data: {
                propsKey: 'x_mils',
                x_mils: props.item.x_mils,
                edited: true,
              },
            })
          "
          @cancel="cancel"
          ><span
            :class="
              (!props.item.x_mils || +props.item.x_mils == 0) && showerror
                ? 'error--text'
                : ''
            "
            >{{
              !props.item.x_mils.toString().length > 0 && showerror
                ? "Required"
                : props.item.x_mils
            }}</span
          >
          <template v-slot:input>
            <v-text-field
              v-model.number="props.item.x_mils"
              label="X-Coordinate (mils)"
              type="number"
              step="0.0001"
              :disabled="!isInitialState && !isAdmin"
            ></v-text-field>
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:[`item.y`]="props">
        <v-edit-dialog
          v-if="!enabledViewMils"
          :return-value="props.item.y"
          :large="isInitialState || isAdmin"
          @save="
            save('coordinate', {
              pk: props.item.pk,
              data: {
                propsKey: 'y',
                y: props.item.y,
                edited: true,
              },
            })
          "
          @cancel="cancel"
          ><span
            :class="
              (!props.item.y || +props.item.y == 0) && showerror
                ? 'error--text'
                : ''
            "
            >{{
              !props.item.y.toString().length > 0 && showerror
                ? "Required"
                : props.item.y
            }}</span
          >
          <template v-slot:input>
            <v-text-field
              v-model.number="props.item.y"
              label="Y-Coordinate (mm)"
              type="number"
              step="0.0001"
              :disabled="!isInitialState && !isAdmin"
            ></v-text-field>
          </template>
        </v-edit-dialog>
        <v-edit-dialog
          v-else
          :return-value="props.item.y_mils"
          :large="isInitialState || isAdmin"
          @save="
            save('coordinate_mils', {
              pk: props.item.pk,
              data: {
                propsKey: 'y_mils',
                y_mils: props.item.y_mils,
                edited: true,
              },
            })
          "
          @cancel="cancel"
          ><span
            :class="
              (!props.item.y_mils || +props.item.y_mils == 0) && showerror
                ? 'error--text'
                : ''
            "
            >{{
              !props.item.y_mils.toString().length > 0 && showerror
                ? "Required"
                : props.item.y_mils
            }}</span
          >
          <template v-slot:input>
            <v-text-field
              v-model.number="props.item.y_mils"
              label="Y-Coordinate (mils)"
              type="number"
              step="0.0001"
              :disabled="!isInitialState && !isAdmin"
            ></v-text-field>
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:[`item.pressure_pin_assembly`]="props">
        <v-edit-dialog
          :return-value="props.item.pressure_pin_assembly"
          :large="isInitialState || isAdmin"
          @save="
            save('pressurepin', {
              pk: props.item.pk,
              data: {
                pk:
                  props.item.pressure_pin_assemblyPk.pk ||
                  props.item.pressure_pin_assemblyPk,
                name:
                  props.item.pressure_pin_assemblyPk.description ||
                  props.item.pressure_pin_assembly,
                edited: true,
              },
            })
          "
          @cancel="cancel('pressure_pin_assemblyPk', props.item.pk)"
          @close="cancel('pressure_pin_assemblyPk', props.item.pk)"
          ><span
            :class="
              !validPressurePin(props.item.pressure_pin_assemblyPk) && showerror
                ? 'error--text'
                : ''
            "
            >{{
              !props.item.pressure_pin_assembly && showerror
                ? "Required"
                : props.item.pressure_pin_assembly
            }}</span
          >
          <template v-slot:input>
            <v-autocomplete
              :items="filteredAssembly"
              label="Pressure Pin"
              item-text="description"
              item-value="pk"
              v-model="props.item.pressure_pin_assemblyPk"
              return-object
              :disabled="!isInitialState && !isAdmin"
              no-data-text="Out of stock, contact us"
            ></v-autocomplete>
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:[`item.enabled`]="props">
        <v-edit-dialog
          :return-value.sync="props.item.enabled"
          :large="isInitialState || isAdmin"
          @save="
            save('coordinate', {
              pk: props.item.pk,
              data: {
                propsKey: 'enabled',
                enabled: props.item.enabled,
                edited: true,
              },
            })
          "
          @cancel="cancel"
        >
          <v-chip
            :color="
              isInitialState || isAdmin
                ? props.item.enabled
                  ? 'secondary'
                  : 'warning'
                : ''
            "
            >{{ props.item.enabled ? "Yes" : "No" }}</v-chip
          >
          <template v-slot:input>
            <v-select
              :items="yesNo"
              label="Enabled?"
              item-text="text"
              item-value="value"
              v-model="props.item.enabled"
              :disabled="!isInitialState && !isAdmin"
            ></v-select>
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon
              color="warning"
              small
              v-on="on"
              v-show="item.edited"
              class="mx-1"
              @click.stop="showDialog(item, 'undo')"
            >
              mdi-undo
            </v-icon>
          </template>
          <span>Undo Edit</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon
              color="primary"
              small
              v-on="on"
              v-show="item.edited"
              class="mx-1"
              @click.stop="showDialog(item, 'save')"
            >
              mdi-content-save
            </v-icon>
          </template>
          <span>Save Pressure Pin</span>
        </v-tooltip>
        <PressurePinForm
          :editMode="true"
          :override="override"
          :disabled="(!isInitialState && !isAdmin) || bulkEditMode"
          @saveData="saveForm"
          :itemData="cloneData(item)"
          :enabledViewMils="enabledViewMils"
        />
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <span v-on="on">
              <v-icon
                color="warning"
                small
                class="mx-1"
                :disabled="(!isInitialState && !isAdmin) || bulkEditMode"
                @click.stop="showDialog(item, 'delete')"
              >
                mdi-delete
              </v-icon>
            </span>
          </template>
          <span>{{
            isInitialState || isAdmin
              ? "Delete Pressure Pin"
              : "Delete is no longer available"
          }}</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-snackbar v-model="snack" :timeout="2000" color="primary" centered>
      <v-container class="body-1 py-0">
        <v-row dense>
          <v-col class="d-flex justify-center">
            <div>
              {{ snackText }}
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-snackbar>
    <BaseBottomDialog
      :dialog="bottomDialog"
      :message="confirmMessage"
      :action="confirmAction"
      @save="saveHandler"
      @delete="deleteHandler"
      @navToNext="navHandler"
      @undo="undoHandler"
      @discard="discardHandler"
      @deleteRows="deleteSelected"
      @donothing="stopDialog"
    />
  </v-card>
</template>
<script>
import BottomDialog from "@/mixins/BottomDialog";
import PageOptions from "@/mixins/PageOptions";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "PressurePinPage",
  mixins: [BottomDialog, PageOptions],
  props: {
    override: Boolean,
    showerror: Boolean,
    bulkEditMode: Boolean,
    isGerberExists: Boolean,
    isInitialState: Boolean,
    isAdmin: Boolean,
    gerberValid: Boolean,
    enabledViewMils: Boolean,
    previousViewMils: Boolean,
  },
  components: {
    PressurePinForm: () => import("../components/PressurePinForm.vue"),
    InputQtyTextField: () =>
      import("@/modules/common/components/InputQtyTextField.vue"),
  },
  data() {
    return {
      selectedItem: null,
      snack: false,
      snackText: "A new Pressure Pin has been added.",
      selection: [],
      yesNo: [
        {
          value: true,
          text: "Yes",
        },
        {
          value: false,
          text: "No",
        },
      ],
      scale: 1,
      points: null,
      MM_TO_MILS: 0.0254,
    };
  },
  computed: {
    ...mapGetters({
      pressurePins: "pressurepins/pressurePins",
      pressureAssembly: "dutconfigs/pressurePins",
      fixtureConfig: "fixturedesign/fixtureConfig",
      fixtureDesign: "fixturedesign/fixtureDesign",
      dutConfigs: "dutconfigs/dutConfigs",
      ppCount: "pressurepins/ppCount",
    }),
    arrayList() {
      return this.pressurePins;
    },
    filteredAssembly() {
      return (this.override
        ? this.pressureAssembly
        : this.pressureAssembly.filter((element) => {
            return element.fixtures.includes(this.fixtureConfig.pk);
          })
      ).sort((a, b) => (a.description > b.description ? 1 : -1));
    },
    isEditing() {
      return this.pressurePins.find((element) => element.edited);
    },
    editedPressurePins() {
      return this.pressurePins.filter((element) => element.edited);
    },
  },
  methods: {
    getHeaders(inMils) {
      return [
        {
          text: "Name",
          align: "start",
          value: "name",
          class: this.bulkEditMode
            ? "grey--text body-2 font-weight-bold"
            : "primary--text body-2 font-weight-bold",
        },
        {
          text: inMils ? "X-Coord (mils)" : "X-Coord (mm)",
          align: "start",
          value: "x",
          class: "primary--text body-2 font-weight-bold",
        },
        {
          text: inMils ? "Y-Coord (mils)" : "Y-Coord (mm)",
          align: "start",
          value: "y",
          class: "primary--text body-2 font-weight-bold",
        },
        {
          text: "Pressure Pin",
          align: "center",
          value: "pressure_pin_assembly",
          class: "primary--text body-2 font-weight-bold",
        },
        {
          text: "Enabled",
          align: "start",
          value: "enabled",
          class: "primary--text body-2 font-weight-bold",
        },
        {
          text: "Actions",
          align: "end",
          value: "actions",
          sortable: false,
          class: "primary--text body-2 font-weight-bold",
        },
      ];
    },
    ...mapActions({
      removePressurePin: "pressurepins/deletePressurePin",
      savePressurePins: "pressurepins/updatePressurePins",
      savePressurePin: "pressurepins/updatePressurePin",
      saveInline: "pressurepins/updateInline",
      saveDirectSync: "pressurepins/updateDirectSync",
      saveCoordinate: "pressurepins/updateCoordinate",
      saveCoordinateMils: "pressurepins/updateCoordinateMils",
      saveAllEdited: "pressurepins/updateAllEdited",
      undoChanges: "pressurepins/restorePressurePin",
      updateCell: "pressurepins/revertCellObject",
      discardPressurePins: "pressurepins/restoreAllPressurePins",
      removeSelectedPressurePins: "pressurepins/deleteSelectedPressurePins",
      toggleLoading: "ui/loading",
    }),
    validPressurePin(assembly) {
      return this.pressureAssembly
        .filter((element) => {
          return element.fixtures.includes(this.fixtureConfig.pk);
        })
        .map((item) => item.pk)
        .includes(assembly);
    },
    cloneData(item) {
      return JSON.parse(JSON.stringify(item));
    },
    async saveHandler() {
      try {
        const actionPayload = {
          editMode: true,
          data: this.selectedItem,
        };
        await this.saveInline({
          payload: actionPayload,
          projectId: this.$route.params.id,
          fdId: this.fixtureDesign.pk,
        });
        if (this.isGerberExists && this.gerberValid) {
          this.$emit("updateRender");
        }
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
      this.stopDialog();
    },
    async deleteHandler() {
      try {
        await this.removePressurePin({
          payload: this.selectedItem,
          dutId: this.$route.params.did,
          projectId: this.$route.params.id,
          fdId: this.fixtureDesign.pk,
        });
        if (this.isGerberExists && this.gerberValid) {
          this.$emit("updateRender");
        }
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
      this.stopDialog();
    },
    undoHandler() {
      this.undoChanges(this.selectedItem.pk);
      if (this.isGerberExists && this.gerberValid) {
        this.$emit("updateRender");
      }
      this.stopDialog();
    },
    showDialog(item, nextAction) {
      if (
        nextAction == "save" &&
        !this.override &&
        (!item.name || !item.x || !item.y || !item.pressure_pin_assembly)
      ) {
        this.$store.commit("ui/SNACK_BAR", "Some required data are missing.");
        return;
      }
      this.confirmAction = nextAction;
      this.confirmMessage =
        nextAction == "save" && this.enabledViewMils
          ? `You specified mils, but FixturFab works in mm. Displaying float values in mils, but each will be converted to mm upon saving.`
          : `Are you sure to ${nextAction} Pressure Pin ${item.name}?`;
      this.selectedItem = item;
      this.bottomDialog = true;
    },
    showSimpleDialog(nextAction) {
      this.confirmAction = nextAction;
      this.confirmMessage = `Are you sure to ${nextAction} all changes?`;
      this.bottomDialog = true;
    },
    save(type, payload) {
      if (type === "pressurepin") {
        if (
          this.selection.length > 1 &&
          this.selection.find((item) => item.pk == payload.pk)
        ) {
          this.bulkHandler({ payload, fieldName: "savePressurePin" });
        } else {
          this.savePressurePin(payload);
        }
      } else if (type === "coordinate") {
        if (
          this.selection.length > 1 &&
          this.selection.find((item) => item.pk == payload.pk)
        ) {
          this.bulkHandler({ payload, fieldName: "saveCoordinate" });
        } else {
          this.saveCoordinate(payload);
        }
      } else if (type === "coordinate_mils") {
        if (
          this.selection.length > 1 &&
          this.selection.find((item) => item.pk == payload.pk)
        ) {
          this.bulkHandler({ payload, fieldName: "saveCoordinateMils" });
        } else {
          this.saveCoordinateMils(payload);
        }
      } else {
        this.saveDirectSync(payload);
      }
      if (this.isGerberExists && this.gerberValid) {
        this.$emit("updateRender");
      }
    },
    cancel(type, pk) {
      this.updateCell({ type, pk });
      this.selectedItem = null;
    },
    async saveForm(payload) {
      try {
        await this.savePressurePins({
          payload: payload,
          dutId: this.$route.params.did,
          projectId: this.$route.params.id,
          fdId: this.fixtureDesign.pk,
        });
        if (this.isGerberExists && this.gerberValid) {
          this.$emit("updateRender");
        }
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    async saveAllInLine() {
      const validator = this.editedPressurePins.every((pressurepin) => {
        if (
          !this.override &&
          (!pressurepin.name ||
            !pressurepin.x ||
            !pressurepin.y ||
            !pressurepin.pressure_pin_assembly)
        ) {
          this.$store.commit("ui/SNACK_BAR", "Some required data are missing.");
          return false;
        }
        return true;
      });
      if (!validator) return;
      const actionPayload = this.editedPressurePins.map((edited) => ({
        pk: edited.pk,
        project: edited.project,
        name: edited.name,
        x: edited.x,
        y: edited.y,
        pressure_pin_assembly: edited.pressure_pin_assemblyPk,
        enabled: edited.enabled,
      }));
      try {
        await this.saveAllEdited({
          arrayList: {
            project_id: this.$route.params.id,
            dut_id: this.dutConfigs.pk,
            data: {
              pressure_pins: actionPayload,
            },
            data_type: "pressure-pins",
          },
          projectId: this.$route.params.id,
          fdId: this.fixtureDesign.pk,
          pressurepins: [...this.editedPressurePins],
        });
        if (this.isGerberExists && this.gerberValid) {
          this.$emit("updateRender");
        }
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
      this.stopDialog();
    },
    discardHandler() {
      this.stopDialog();
      this.discardPressurePins();
      if (this.isGerberExists && this.gerberValid) {
        this.$emit("updateRender");
      }
    },
    emitHandler(params) {
      if (this.isEditing) {
        this.confirmAction = "navToNext";
        this.confirmMessage =
          "Are you sure to navigate away from current page? (Changes to highlighted rows may be discarded)";
        this.params = params;
        this.bottomDialog = true;
      } else {
        this.proceedEmit(params);
      }
    },
    itemRowBg(item) {
      return item.edited ? "edited-style" : "";
    },
    navHandler() {
      this.stopDialog();
      this.proceedEmit(this.params);
    },
    proceedEmit(params) {
      this.$emit("nextStep", params);
    },
    bulkHandler({ payload, fieldName }) {
      this.selection.forEach((item) => {
        const actionPayload = {
          pk: item.pk,
          data: payload.data,
        };
        this[fieldName](actionPayload);
      });
    },
    initSelection() {
      this.selection.splice(0);
    },
    deleteSelectedDialog() {
      if (this.selection.length > 0) {
        this.confirmAction = "deleteRows";
        this.confirmMessage = `Are you sure to delete all selected rows?`;
        this.bottomDialog = true;
      } else {
        this.$store.commit("ui/SNACK_BAR", "No rows to delete.");
      }
    },
    async deleteSelected() {
      this.stopDialog();
      try {
        await this.removeSelectedPressurePins({
          payload: this.selection,
          dutId: this.$route.params.did,
          projectId: this.$route.params.id,
          fdId: this.fixtureDesign.pk,
        });
        this.selection.splice(0);
        if (this.isGerberExists && this.gerberValid) {
          this.$emit("updateRender");
        }
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    async addDefaultHandler(recurrence) {
      for (let i = 0; i < recurrence; i++) {
        const defaultPayload = {
          editMode: false,
          data: {
            project: +this.$route.params.id,
            dut_config: this.dutConfigs.pk,
            name: `pp-${this.pressurePins.length + 1}`,
            x: this.pressurePins.length + 1,
            y: this.pressurePins.length + 1,
            x_mils: (this.pressurePins.length) + 1 * (1 / this.MM_TO_MILS),
            y_mils: (this.pressurePins.length) + 1 * (1 / this.MM_TO_MILS),
            pressure_pin_assembly: 1,
            enabled: true,
          },
        };
        try {
          await this.savePressurePins({
            payload: defaultPayload,
            dutId: this.$route.params.did,
            projectId: this.$route.params.id,
            fdId: this.fixtureDesign.pk,
          });
        } catch (err) {
          this.$store.commit(
            "ui/SNACK_BAR",
            err.message || "Failed to process request, try later."
          );
        }
      }
      this.snack = true;
      if (this.isGerberExists && this.gerberValid) {
        this.$emit("updateRender");
      }
    },
    onInputString() {
      return "if (this.value > 2147483647) this.value = 2147483647; if (this.value < 0) this.value = 0;"
    }
  },
};
</script>
